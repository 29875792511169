<template>
  <div class="wechat">
    <div class="sidestrip">
      <div class="am-dropdown" data-am-dropdown>
        <!--头像插件-->
        <div class="own_head" :style="headImg"></div>
      </div>
      <!--三图标-->
      <div class="sidestrip_icon">
        <img src="../assets/head_2_1.png" />
      </div>
    </div>

    <div class="middle on">
      <div class="wx_search">
        <input
          type="text"
          placeholder="搜索"
          v-model="queryKeyWord"
          @keydown.enter="queryProduct"
        /> 
      </div>
      <div class="office_text">
        <ul
          class="user_list"
          style="
            /*top: -3.78151px;*/
            position: absolute;
            overflow-y: auto;
            height: 99%;
            overflow-x: hidden;
          "
          ref="user_list"
        >
          <li
            :class="selectIndex == index ? 'user_active' : 'user'"
            v-for="(item, index) in productData"
            :key="item.id"
            v-if="item.source != null"
            @click="doChangeImg(index, item.msg_id)"
          >
            <div class="user_head">
              <a :href="item.source" target="_blank">
                <img
                  v-if="item.source.indexOf('.taobao.') > -1"
                  :src="item.product_img + '_120x120.jpg'"
                />
                <img
                  v-if="
                    item.source.indexOf('.jd.') > -1 ||
                    item.source.indexOf('.pinduoduo.') > -1
                  "
                  :src="item.product_img"
                />
              </a>
            </div>
            <div class="user_text">
              <div class="user_name">{{ item.product_name }}</div>
              <p
                class="user_message"
                style="
                  font-size: 16px;
                  font-weight: bold;
                  color: red;
                  float: left;
                "
              >
                <span style="font-size: 12px">¥</span> {{ item.price }}
              </p>
              <p
                class="user_message"
                style="
                  font-size: 12px;
                  color: red;
                  float: right;
                  line-height: 32px;
                "
                v-if="showCommission && item.source.indexOf('.taobao.') > -1"
              >
                赚<span style="font-size: 12px">¥</span
                >{{ ((item.price * item.commission) / 100).toFixed(2) }}
              </p>
              <p
                class="user_message"
                style="
                  font-size: 12px;
                  color: red;
                  float: right;
                  line-height: 32px;
                "
                v-if="
                  showCommission &&
                  (item.source.indexOf('.jd.') > -1 ||
                    item.source.indexOf('.pinduoduo.') > -1)
                "
              >
                赚<span style="font-size: 12px">¥</span>{{ item.commission }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="talk_window">
      <div class="windows_top">
        <div class="windows_top_box">
          <span>{{ info.channel_name }}</span>
        </div>
      </div>
      <!--聊天内容-->
      <div class="windows_body">
        <div
          class="office_text"
          style="height: 99%; overflow-y: auto; overflow-x: hidden"
          ref="office_text"
        >
          <ul class="content" id="chatbox">
            <li class="other s0" v-for="(item, index) in data" :key="index">
             <div style="width:34px;display:flex;flex-direction: column;justify-content: space-between;">
              <img
                class="other_img"
                :src="info.channel_img"
                :title="info.channel_name"
              />
              <div class="btnCopy" :ref="'c_' + item.msg_id" @click="doCopy(item.msg_id)"></div>
             </div>
             <div style="display:flex:flex-direction:column;margin-left:12px">
              <div><div class="msg_time">{{ item.create_date }}</div></div>
              <div> <span
                :id="item.msg_id"
                :name="item.msg_id"
                :ref="'p_' + item.msg_id"
                v-html="formatEmoji(item.format_content)" style="word-break:break-all"
              ></span></div>
             </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <my-toast :toastShow="showMsg" :msg="msg"></my-toast>
  </div>
</template>
<script>
import { getPdProducts, getNextChannelMsg } from "@/api/channel.js";
import MyToast from "./MyToast.vue";
export default {
  components: { MyToast },
  name: "wechat",
  data() {
    return {
      data: [],
      productData: [],
      info: {},
      headImg: "",
      pageIndex: 1,
      pageSize: 120,
      selectIndex: 0,
      ids: [],
      socket: null,
      socketUrl: "wss://m.hwqsm.com/ws",
      maxId: "",
      tempData: [],
      queryKeyWord: "",
      showMsg: false,
      beatCount: 0,
      handlerTime: 0,
      msg:'新的推送'
    };
  },
  props: {
    channelName: {
      type: String,
      default: "HWQSMCS",
    },
    showCommission: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    let _this = this;
    getPdProducts(_this.channelName, this.pageIndex, this.pageSize).then(
      (res) => {
        console.info(res);
        if(res.data.info==null){
            window.location.href="http://shangjia.hwqsm.com/html/channel/404.html";
        }
        _this.data = res.data.data.reverse();
        _this.productData = res.data.product_data.reverse();
        _this.tempData = _this.productData;
        _this.info = res.data.info;
        
        window.document.title=_this.info.channel_name;
        _this.headImg = "background-image:url(" + _this.info.channel_img + ")";
        _this.$forceUpdate();
        _this.maxId = res.data.id;
      }
    );
  },
  mounted() {
    let _this = this;
    this.init();
    setTimeout(() => {
      _this.$refs.office_text.scrollTo(0, _this.$refs.office_text.scrollHeight);
    }, 2000);
  },
  destroyed() {
    this.socket.onclose = this.close;
  },
  methods: {
    show(e){
      e.srcElement.src="../assets/copy.png";
    },
    hide(e){
      e.srcElement.src="../assets/copy_press.png";
    },
    doCopy(id){
       
      let dom=this.$refs["p_" + id][0]; 
      if (window.getSelection) {//chrome等主流浏览器
                let selection = window.getSelection();
                let range = document.createRange();
                range.selectNode(dom);
                selection.removeAllRanges();
                selection.addRange(range);
            } else if (document.body.createTextRange) {//ie
                let range = document.body.createTextRange();
                range.moveToElementText(dom);
                range.select();
            }
            document.execCommand('copy'); 
      
            if(dom.firstChild.tagName=='IMG'){
              this.msg="请右键【复制图片】";
              this.showMsg=true;
                window.setTimeout(()=>{ 
              this.showMsg=false; 
            },1000*3)
              return;
            }
            this.msg="复制成功";
            this.showMsg=true; 
            window.setTimeout(()=>{ 
            this.showMsg=false; 
            },1000*3)
    }, 
    formatEmoji(s) {
      let arr = s.match(/\\u([0-9]|[a-z]|[A-Z]){4,5}/g);
      if (arr != null) {
        if (arr.length > 0) {
          arr.forEach((e) => {
            s = s.replace(e, eval("'" + e + "'"));
          });
          return s;
        }
      }
      return s;
    },
    queryProduct(e) {
      if (this.queryKeyWord == "") {
        this.productData = this.tempData;
        return;
      }
      var d = [];
      this.tempData.map((v, index) => {
        if (v.product_name.indexOf(this.queryKeyWord) >= 0) {
          return d.push(v);
        }
      });
      this.productData = d.reverse();
    },
    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    doChangeImg(index, msgId) {
      this.selectIndex = index;
      if (this.$refs["p_" + msgId]) {
        if (this.$refs["p_" + msgId].length > 0) {
          //滚动条滚动到指定位置
          this.$refs["p_" + msgId][0].parentNode.parentNode.parentNode.parentNode.parentNode.scrollTo(
            0,
            this.$refs["p_" + msgId][0].parentNode.offsetTop
          );
        }
      }
    },
    //启动websocket
    init() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.socketUrl);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;

        this.socket.onclose = this.onclose;
      }
    },
    open() {
      console.log("socket连接成功");
    },
    error() {
      console.log("socket连接错误");
      console.log("连接错误");
    },
    onclose() {
      console.log("socket连接失败");
    },
    getMessage(msg) {
      let _this = this;
      console.info(msg);
      if (msg.data == "pong") {
        this.beatCount = 0;
        return;
      }
      var result = JSON.parse(msg.data);
      switch (result.RequestType) {
        case "Hw.Tools.Service.ConnectService": {
          //绑定当前业务数据
          var data = _this.CreateConnection(_this.channelName);
          _this.send(JSON.stringify(data));
          //开始发送心跳数据
          _this.setHertBeat();
          break;
        }
        case "Hw.Tools.Service.AccepetAppMsg": {
          _this.showMsg = true;
          setTimeout(() => {
            getNextChannelMsg(_this.maxId, _this.channelName, 1, 10).then(
              (res) => {
                _this.showMsg = false;
                if (res.data) {
                  if (res.data.data) {
                    let temp = res.data.data.reverse();
                    temp.forEach((item) => {
                       let p= _this.data.find((n)=>n.id==item.id);
                       if(p==undefined)
                          _this.data.push(item); 
                    });
                  }
                  if (res.data.product_data) {
                    res.data.product_data.forEach((item) => {
                      let p= _this.productData.find((n)=>n.id==item.id);
                      if(p==undefined)
                        _this.productData.unshift(item);
                    });
                  }
                  if(res.data.id){
                     _this.maxId = res.data.id;
                  }
                }

                console.info((this.$refs.office_text.scrollHeight-this.$refs.office_text.clientHeight-this.$refs.office_text.scrollTop));
                if((this.$refs.office_text.scrollHeight-this.$refs.office_text.clientHeight-this.$refs.office_text.scrollTop)<=0)
                {
                  setTimeout(() => {
                    _this.$refs.office_text.scrollTo(
                      0,
                      _this.$refs.office_text.scrollHeight
                    );
                  }, 500);
                }
                //_this.productData=_this.productData.concat(res.data.product_data)
               
              }
            );
          }, 3000);
          break;
        }
        case "pong": {
          this.beatCount = 0;
          console.info("pong");
        }
      }
    },
    send(params) {
      this.socket.send(params);
    },
    close() {
      console.log("socket已经关闭");
    },
    CreateRequest(data, sType, sReciver, sSender, sSeq, sNext, sFlowId) {
      var obj = {};

      obj.Data = data;
      obj.RequestId = Math.random();
      obj.RequestDate = new Date().getTime();
      obj.ClientId = null;
      obj.RequestType = sType;
      obj.Reciver = sReciver;
      obj.Sender = sSender;
      obj.SEQ = sSeq;
      obj.Next = sNext;
      obj.FlowId = sFlowId;

      return obj;
    },
    /**
     * 创建连接请求
     */
    CreateConnection(code) {
      return this.CreateStartRequest(
        { HwAccount: code },
        "Hw.Tools.Service.ConnectService",
        code
      );
    },
    CreateStartRequest(data, sType, sSender) {
      return this.CreateRequest(data, sType, "server", sSender, 0, 0, null);
    },
    CreateBeatRequest(sSender) {
      return this.CreateRequest("", "ping", "server", sSender, 0, 0, null);
    },
    setHertBeat() {
      let _this = this;
      _this.handlerTime = setInterval(() => {
        if (_this.beatCount >= 6) {
          _this.beatCount = 0;
          console.info("正在断线重联");
          clearInterval(_this.handlerTime);
          _this.socket = null;
          _this.init();
        } else {
          _this.beatCount = _this.beatCount + 1;
          var data = _this.CreateBeatRequest(_this.channelName);
          _this.send(JSON.stringify(data));
        }
      }, 3000);
    },
  },
};
</script>
<style>
.btnCopy:hover{
  background:url('http://static.hwqsm.com/web/images/copy_press.png') no-repeat;
  background-size: 100% 100%;
}
.btnCopy{
  position: relative;
  right:-30px;margin-bottom: 10px;
  cursor: pointer; 
  width:16px;
  height:16px;
  background:url('http://static.hwqsm.com/web/images/copy.png') no-repeat;
  background-size: 100% 100%;
}
.other span img {
  width: 180px !important;
  height: unset !important;
  padding-top: 12px;
  border-radius: 4px;
}
body {
  margin: 0px;
}
img {
  border-radius: 6px;
}
/*定义滚动条样式（高宽及背景）*/
::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
  background: rgb(255, 255, 255, 1);
}
/*定义滚动条轨道（凹槽）样式*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* 较少使用 */
  border-radius: 3px;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}

ul {
  margin: 0px;
  padding: 0px;
}
.wechat {
  /*width: 970px;*/
  height: 550px;
  min-height: 400px;
  background: #fff;
  overflow: hidden;
  /*box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);*/
  /*border-radius: 4px;*/
}
.am-dropdown {
  position: relative;
  display: inline-block;
}
.sidestrip {
  width: 60px;
  background: #2a2e32;
  float: left;
  height: 100%;
  border-radius: 4px 0 0 4px;
  padding-top: 20px;
  position: relative;
  display: none;
}

.own_head {
  width: 34px;
  height: 34px;
  margin-left: 13px;
  border-radius: 2px;
  background-size: 34px;
}

.am-dropdown-content {
  width: 300px;
  margin-left: 20px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  top: 0px;
  left: 32px;
}

.am-dropdown-content:after,
.am-dropdown-content:before {
  display: none;
}

.own_head_top {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 25px;
}

.own_name {
  font-size: 18px;
  color: #333;
  margin-bottom: 6px;
}

.own_name > img {
  width: 14px;
  margin-left: 8px;
  margin-top: 3px;
}

.own_numb {
  font-size: 14px;
  color: #999;
}

.own_head_top > img {
  width: 60px;
  float: right;
}

.own_head_top_text {
  float: left;
}

.own_head_bottom {
  padding-top: 20px;
}

.own_head_bottom p {
  color: #333;
}

.own_head_bottom p > span {
  color: #999;
  margin-right: 10px;
}

.own_head_bottom_img {
  display: inline-block;
  float: right;
  margin-top: 10px;
}

.own_head_bottom_img a img {
  margin-left: 20px;
}

.sidestrip_icon {
  margin-top: 30px;
  padding: 0 18px;
  width: 60px;
}

.sidestrip_icon a {
  width: 100%;
  height: 24px;
  display: block;
  margin-bottom: 30px;
}

.sidestrip_bc {
  background: url(../assets/head_5.png) no-repeat;
  position: absolute;
  bottom: 20px;
  width: 16px;
  height: 13px;
  left: 22px;
}

ul.am-dropdown-content {
  padding: 0;
  bottom: 10px;
  top: auto;
  left: 40px;
  background: #393d40;
  width: auto;
  border-radius: 0;
}

ul.am-dropdown-content > li > a {
  color: #fff;
}

ul.am-dropdown-content > .am-active > a,
ul.am-dropdown-content > .am-active > a:focus,
ul.am-dropdown-content > .am-active > a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #333;
}

ul.am-dropdown-content > li > a:focus,
ul.am-dropdown-content > li > a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #333;
}

.am-dimmer {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
}

.middle {
  width: 320px;
  height: 100%;
  float: left;
  background: #e6e5e5;
  overflow: hidden;
  display: none;
}

.on {
  display: block;
}

.user_list,
.friends_list,
.icon_list {
  width: 100%;
  list-style-type: none;
}

.wx_search {
  padding: 16px 20px;
  display: inline-block;
  width: 95%;
  float: inherit;
  background-color: #eeeae8;
}

.wx_search input {
  width: 260px;

  background: #e5e2e2;
  border: 1px solid #d9d7d6;
  padding-left: 5px;
  outline: 0;
  float: left;
  padding: 6px 6px 6px 16px;
  border-radius: 100px;
  background-color: #dbd9d8;
}

.wx_search button {
  font-size: 20px;
  font: initial;
  background: #e5e2e2;
  border: 1px solid #d9d7d6;
  float: right;
  color: #585858;
  outline: 0;
  height: 25px;
  width: 25px;
}

.wx_search button:hover {
  background: #d0d0d0;
  border: 1px solid #b9b6b5;
}

.user_list li {
  /*width: 100%;*/
  height: 80px;
  padding: 12px 0px 12px 12px;
}

.friends_list li {
  width: 100%;
  padding-top: 12px;
  border-bottom: 1px solid #dddbdb;
}

.user_list li:hover,
.icon_list li:hover {
  background: #dedbdb;
}

.friends_box:hover {
  background: #dedbdb;
}

.user_head {
  height: 80px;
  width: 80px;
  border-radius: 4px;
  float: left;
  overflow: hidden;
}

.user_head img {
  width: 80px;
  transition: all 0.2s linear;
}
.user_head img:hover {
  transform: scale(0.98);
}
.user_text {
  float: left;
  height: 40px;
  width: 65%;
  padding-left: 8px;
}

.friends_text {
  float: left;
  line-height: 40px;
  width: 180px;
  padding-left: 8px;
}

.friends_text p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user_text p {
}

.user_name {
  color: #333;
  font-size: 14px;
  height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.user_message {
  color: #999;
  font-size: 12px;
}

.user_time {
  float: right;
  color: #999;
  font-size: 12px;
}

.zUIpanelScrollBox,
.zUIpanelScrollBar {
  width: 7px;
  top: 4px;
  right: 0px;
  border-radius: 5px;
}

.zUIpanelScrollBox {
  background: black;
  opacity: 0;
  filter: alpha(opacity=5);
}

.zUIpanelScrollBar {
  background: rgba(0, 0, 0, 0.1);
  filter: alpha(opacity=80);
}

.office_text {
  width: 100%;
  height: calc(100% - 62px);
  position: relative;
  display: inline-block;
}

.friends_list li > p {
  font-size: 12px;
  color: #999;
  line-height: 26px;
  padding-left: 12px;
}

.friends_box {
  padding: 12px 0;
  width: 100%;
  padding-left: 12px;
  display: flex;
}

.icon_list li {
  width: 100%;
  height: 40px;
}

.icon {
  float: left;
  height: 100%;
  width: 50px;
  position: relative;
}

.icon_list li span {
  display: inline-block;
  line-height: 40px;
}

.icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.icon_active,
.user_active {
  background: #dedbdb;
  cursor: pointer;
}
.user {
  cursor: pointer;
}

/*******聊天窗口******/

.talk_window {
  max-width: 550px;
  height: 100%;
  margin-left: 320px;
  background: #f5f5f5;
}

.windows_top {
  height: 62px;
  border-bottom: 1px solid #e7e7e7;
}

.windows_top_box > span {
  font-size: 18px;
  color: #333;
  line-height: 62px;
  padding-left: 28px;
  display: inline-block;
}

.windows_top_box {
  width: 100%;
  height: 100%;
  position: relative;
}

.window_icon {
  position: absolute;
  top: -10px;
  right: 0;
}

.window_icon li {
  float: left;
  width: 34px;
  height: 26px;
  position: relative;
}

.window_icon li:hover {
  background: #e3e3e3;
}

.window_icon li a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.windows_input {
  border-top: 1px solid #ececec;
  height: 145px;
  width: 100%;
}

.windows_body {
  height: calc(100% - 62px);
  width: 100%;
}

.input_icon {
  height: 42px;
  padding: 0 28px;
}

.input_icon a {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 24px;
  margin-top: 11px;
  margin-right: 8px;
}

.input_icon a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input_box textarea {
  width: calc(100% - 56px);
  height: calc(100% - 42px);
  border: none;
  outline: 0;
  background: #f5f5f5;
  resize: none;
  margin-left: 28px;
  font-size: 14px;
  line-height: 20px;
}

.input_box {
  height: calc(100% - 42px);
}

.office_text1 {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
}

#send {
  border: 1px solid #e5e5e5;
  background: #f5f5f5;
  color: #666;
  padding: 0 8px;
  outline: 0;
  height: 26px;
  float: right;
  margin-top: 8px;
  margin-right: 28px;
}

#send:hover {
  background: #09bb07;
  color: #fff;
  border: 1px solid #09bb07;
}

.optionLeft {
  background: rgb(158, 243, 106);
  float: right;
}

.windows_body p {
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 4px;
}

/*区分聊天*/

.content {
  width: 100%;
  padding: 10px 20px;
}

.me img {
  float: right;
  width: 34px;
  height: 34px;
  border-radius: 2px;
}

.me span {
  float: right;
  background: #9eea6a;
}

.me {
  margin-top: 10px;
  padding-left: 10px;
  display: block;
  clear: both;
  overflow: hidden;
  float: right;
  margin-bottom: 5px;
}

.me span {
  background: #9eea6a;
  padding: 7px;
  border-radius: 4px;
  margin: 0 10px;
  max-width: 80%;
  border: 1px solid #9eea6a;
  position: relative;
}

.me span:before {
  content: " ";
  position: absolute;
  top: 9px;
  right: 100%;
  border: 6px solid transparent;
  border-left-color: #9eea6a;
  right: inherit;
  left: 100%;
}

.other span {
  float: right;
  background: #9eea6a;
}

.other {
  width: 400px;
  display: flex;
  clear: both;
  overflow: hidden;
  float: left;
  margin-bottom: 8px;
  font-size: 14px;
  flex-direction: row;
}
.other_img {
  float: left;
  border-radius: 6px;
  padding: 6px;

  width: 34px;
  height: 34px;
  border-radius: 2px;
}
.other .msg_time {
  color: #ccc;
  display: inline;
  margin: 24px;
  padding-right: 120px;
}
.other span {
  background: #fff;
  padding: 12px;
  border-radius: 6px;
  float: left;
  max-width: 260px;
  border: 1px solid #ededed;
  position: relative;
  margin-left: 12px;
  line-height: 24px;
  word-break:break-all;
}

.other span:before {
  content: "";
  position: absolute;
  top: 9px;
  right: 100%;
  border: 6px solid transparent;
  border-right-color: #fff;
  right: inherit;
  right: 100%;
}
</style>
