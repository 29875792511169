import axios from 'axios'   
import qs from 'qs'
import md5 from 'js-md5'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "https://ms.zaozaosheng.com/zzsapi/pd",
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token 
    if (error.response.status === 403){
        console.info(data)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        console.info(data)
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {  
  console.info(config)
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  const data = config.data || {}
  data.nonce = Math.floor(Math.random(0, 1) * 10000)
  data.timestamp = Date.parse(new Date()) 
  data.token = "undefined" 
  const sData = qs.stringify(data)
  const arrData = sData.split('&')
  const arrSortData = arrData.sort(function (a, b) {
    return a.localeCompare(b, 'zh-CN')
  })
  let sSortData = arrSortData.join('&')
  let sign = btoa(sSortData)
  sign = btoa(sign)
  sign = md5(sign)
  sSortData = sSortData + '&sign=' + sign
  config.data = sSortData

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data) {
    if (response.data.data) {
      if (response.data.data.code) {
        if (response.data.data.code === -1) {
            
            return
        }
      }
    }

    if (response.data.code) {
      if (response.data.code === -1) { 
        return
      }
    }
  }

  return response.data
}, errorHandler) 
export default request

export { 
  request as axios
}
