<template>
    <div class="toast" v-show="toastShow">{{msg}}</div>
</template>
<script>
export default {
    name:"toast",
    props:{
        toastShow:{
            type:Boolean,
            default:false
        },
        msg:{
            type:String,
            default:'新的推送'
        }
    },
    data(){
        return {}
    },

}
</script>
<style>
.toast{
    position: fixed;
    z-index: 2000;
    left: 40%;
    top: 25%;
    transition: all .3s linear;
    -webkit-transform:translateX(50%),translateY(-50%);
    -moz-transform:translateX(50%),translateY(-50%);
    -ms-transform:translateX(50%),translateY(-50%);
    -o-transform:translateX(50%),translateY(-50%);
    transform:translateX(50%),translateY(-50%); 
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: rgba(17, 17, 17, 0.7);
    height: 45px;
    line-height: 45px;
    padding: 0px 25px;
    max-width: 150px;
}
</style>