import request from '@/utils/request'

const channelApi={
    getPdChannels:'getPdChannels',
    getPdProducts:'getPdProducts',
    getNextChannelMsg:'getNextChannelMsg'
}

export function getPdChannels(channelType,pageNo,pageSize){
    return request({
        url: channelApi.getPdChannels,
        method: 'post',
        data: {channel_type:channelType,page_no:pageNo,page_size:pageSize}
      })
}

export function getPdProducts(channelName,pageNo,pageSize){
    return request({
        url: channelApi.getPdProducts,
        method: 'post',
        data: {channel_name:channelName,page_no:pageNo,page_size:pageSize}
      })
}

export function getNextChannelMsg(id,channelName,pageNo,pageSize){
    if(id==""){
        id="0";
    }
    return request({
        url: channelApi.getNextChannelMsg,
        method: 'post',
        data: {id,channel_name:channelName,page_no:pageNo,page_size:pageSize}
      })
}