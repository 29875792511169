var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wechat"},[_c('div',{staticClass:"sidestrip"},[_c('div',{staticClass:"am-dropdown",attrs:{"data-am-dropdown":""}},[_c('div',{staticClass:"own_head",style:(_vm.headImg)})]),_vm._m(0)]),_c('div',{staticClass:"middle on"},[_c('div',{staticClass:"wx_search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.queryKeyWord),expression:"queryKeyWord"}],attrs:{"type":"text","placeholder":"搜索"},domProps:{"value":(_vm.queryKeyWord)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryProduct.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.queryKeyWord=$event.target.value}}})]),_c('div',{staticClass:"office_text"},[_c('ul',{ref:"user_list",staticClass:"user_list",staticStyle:{"/*top":"-3.78151px","*/\n          position":"absolute","overflow-y":"auto","height":"99%","overflow-x":"hidden"}},_vm._l((_vm.productData),function(item,index){return (item.source != null)?_c('li',{key:item.id,class:_vm.selectIndex == index ? 'user_active' : 'user',on:{"click":function($event){return _vm.doChangeImg(index, item.msg_id)}}},[_c('div',{staticClass:"user_head"},[_c('a',{attrs:{"href":item.source,"target":"_blank"}},[(item.source.indexOf('.taobao.') > -1)?_c('img',{attrs:{"src":item.product_img + '_120x120.jpg'}}):_vm._e(),(
                  item.source.indexOf('.jd.') > -1 ||
                  item.source.indexOf('.pinduoduo.') > -1
                )?_c('img',{attrs:{"src":item.product_img}}):_vm._e()])]),_c('div',{staticClass:"user_text"},[_c('div',{staticClass:"user_name"},[_vm._v(_vm._s(item.product_name))]),_c('p',{staticClass:"user_message",staticStyle:{"font-size":"16px","font-weight":"bold","color":"red","float":"left"}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("¥")]),_vm._v(" "+_vm._s(item.price)+" ")]),(_vm.showCommission && item.source.indexOf('.taobao.') > -1)?_c('p',{staticClass:"user_message",staticStyle:{"font-size":"12px","color":"red","float":"right","line-height":"32px"}},[_vm._v(" 赚"),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("¥")]),_vm._v(_vm._s(((item.price * item.commission) / 100).toFixed(2))+" ")]):_vm._e(),(
                _vm.showCommission &&
                (item.source.indexOf('.jd.') > -1 ||
                  item.source.indexOf('.pinduoduo.') > -1)
              )?_c('p',{staticClass:"user_message",staticStyle:{"font-size":"12px","color":"red","float":"right","line-height":"32px"}},[_vm._v(" 赚"),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("¥")]),_vm._v(_vm._s(item.commission)+" ")]):_vm._e()])]):_vm._e()}),0)])]),_c('div',{staticClass:"talk_window"},[_c('div',{staticClass:"windows_top"},[_c('div',{staticClass:"windows_top_box"},[_c('span',[_vm._v(_vm._s(_vm.info.channel_name))])])]),_c('div',{staticClass:"windows_body"},[_c('div',{ref:"office_text",staticClass:"office_text",staticStyle:{"height":"99%","overflow-y":"auto","overflow-x":"hidden"}},[_c('ul',{staticClass:"content",attrs:{"id":"chatbox"}},_vm._l((_vm.data),function(item,index){return _c('li',{key:index,staticClass:"other s0"},[_c('div',{staticStyle:{"width":"34px","display":"flex","flex-direction":"column","justify-content":"space-between"}},[_c('img',{staticClass:"other_img",attrs:{"src":_vm.info.channel_img,"title":_vm.info.channel_name}}),_c('div',{ref:'c_' + item.msg_id,refInFor:true,staticClass:"btnCopy",on:{"click":function($event){return _vm.doCopy(item.msg_id)}}})]),_c('div',{staticStyle:{"display":"flex:flex-direction:column","margin-left":"12px"}},[_c('div',[_c('div',{staticClass:"msg_time"},[_vm._v(_vm._s(item.create_date))])]),_c('div',[_c('span',{ref:'p_' + item.msg_id,refInFor:true,staticStyle:{"word-break":"break-all"},attrs:{"id":item.msg_id,"name":item.msg_id},domProps:{"innerHTML":_vm._s(_vm.formatEmoji(item.format_content))}})])])])}),0)])])]),_c('my-toast',{attrs:{"toastShow":_vm.showMsg,"msg":_vm.msg}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidestrip_icon"},[_c('img',{attrs:{"src":require("../assets/head_2_1.png")}})])}]

export { render, staticRenderFns }