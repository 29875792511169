<!--
 * @name: 
 * @param: 
 * @return: 
-->
<template>
  <div id="app">
    <channel-details :channelName="cName" :showCommission="showCommission"></channel-details>
  </div>
</template>

<script>
import ChannelDetails from "./components/ChannelDetails.vue";

export default {
  name: "App",
  components: {
    ChannelDetails,
  },
  data() {
    return {
      cName: "",
      showCommission:false,
      enName:''
    };
  },
  beforeMount() {
    this.cName = this.getValueFromLocationByKey("c");
    this.showCommission = this.getValueFromLocationByKey("s")!="";
    this.enName= this.getValueFromLocationByKey("k");
    if(this.enName!=''){
      this.cName=window.atob(this.enName.replace(/\$/g,'='));
    }
    this.cName=this.cName.toUpperCase();
    if(this.cName==''){
      window.location.href="http://shangjia.hwqsm.com/html/channel/404.html";
    }
  },
  methods: {
    getValueFromLocationByKey(key) {
      let sValue = "";
      let sSearch = window.location.search;
      let arrSearch = sSearch.substring(1).split("&");
      for (var i = 0; i < arrSearch.length; i++) {
        let sKeyValue = arrSearch[i];
        let arrKeyValue = sKeyValue.split("=");
        let sKey = arrKeyValue[0]; 
        if (sKey == key) {
          sValue =  arrKeyValue[1];
          break;
        }
      }
      return sValue;
    },
  },
};
</script>
  
<style>
</style>
